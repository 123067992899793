<template>
  <!-- 支线业务-活动详情 -->
  <div class="container">
    <a-page-header
      :title="title"
      sub-title="活动详情"
      @back="() => $router.go(-1)"
    />

    <div class="main-content" v-if="info">
      <div class="content-item">
        <div class="content-item-header">
          <div class="title">活动详情</div>
        </div>

        <div class="content-sub-title">概述</div>

        <div class="ul">
          <div class="li">
            <span>活动名称：</span>
            <span>{{ info.info.name }}</span>
          </div>
          <div class="li">
            <span>活动状态：</span>
            <span>{{ statuText[info.info.status] }}</span>
          </div>
          <div class="li">
            <span>链接类型：</span>
            <span>{{ linktypes[info.info.linktype] }}</span>
          </div>
        </div>

        <div class="ul">
          <div class="li">
            <span>页面链接：</span>
            <span>{{ info.info.url }}</span>
          </div>
        </div>

        <a-divider />

        <div class="content-sub-title content-sub-title-flex">
          <div class="title">活动详情</div>
          <a-button type="link" @click="modalVisible2 = true">
            修改预计时间（定时任务）
          </a-button>
        </div>
        <div class="ul">
          <div class="li">
            <span>开启方式：</span>
            <span>{{ modoTypes[info.info.opentype] }}</span>
          </div>
          <div class="li">
            <span>预计开启时间：</span>
            <span>{{ info.info.startAt }}</span>
          </div>
        </div>
        <div class="ul">
          <div class="li">
            <span>关闭方式：</span>
            <span>{{ modoTypes[info.info.closetype] }}</span>
          </div>
          <div class="li">
            <span>预计关闭时间：</span>
            <span>{{ info.info.endAt }}</span>
          </div>
        </div>
      </div>
    </div>

    <template v-if="info">
      <div class="main-content" v-if="info.info.btype !== 'summer'">
        <div class="content-item">
          <div class="content-item-header content-item-header-left">
            <div class="title">活动商品配置</div>
            <a-button type="link" @click="onComboOpen">
              新增活动商品
            </a-button>
          </div>
          <a-table
            :columns="columns"
            :data-source="info.bcservingList"
            :pagination="false"
            :loading="false"
            :scroll="{ x: 900 }"
          >
            <template slot="picture" slot-scope="record">
              <div class="cover" v-if="record.picture">
                <img class="img" :src="record.picture" />
              </div>
              <span v-else>-</span>
            </template>
            <template slot="oprice" slot-scope="record">
              <a-statistic
                class="a-statistic"
                prefix="￥"
                :value="record.oprice"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" @click="onComboEdit(record)">
                编辑
              </a-button>
              <a-button type="link" @click="onComboDel(record.id)">
                删除
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
    </template>

    <div class="main-content" v-if="info">
      <div class="content-item">
        <div class="content-item-header">
          <div class="title">地址栅栏</div>
        </div>
        <div class="content-sub-title content-sub-title-flex">
          <div class="title">分布式（点对点）</div>
          <a-button type="link" @click="onAddressOpen">
            新增分布点
          </a-button>
        </div>
        <a-table
          :columns="columns2"
          :data-source="addressPoints"
          :pagination="false"
          :loading="false"
          :scroll="{ x: 1400 }"
        >
          <template slot="area" slot-scope="record">
            {{ record.province }}{{ record.city }}{{ record.district }}
          </template>
          <template slot="address" slot-scope="record">
            <a-tooltip placement="top" arrow-point-at-center>
              <template slot="title">
                <span>{{ record.address }}</span>
              </template>
              <div class="address">{{ record.address }}</div>
            </a-tooltip>
          </template>
          <template slot="distance" slot-scope="record">
            {{ record.distance }}km
          </template>
          <template slot="status" slot-scope="record">
            <a-badge
              :status="statuText2[record.status].status"
              :text="statuText2[record.status].text"
            />
          </template>
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="onAddressEdit(record)">
              编辑
            </a-button>
            <a-button
              type="link"
              @click="onChangeAddressStatus(record.id, record.status)"
            >
              <span v-if="record.status === 1">禁用</span>
              <span v-else>启用</span>
            </a-button>
            <a-button
              type="link"
              @click="removeActivityComboAddress(record.id)"
            >
              删除
            </a-button>
          </template>
        </a-table>

        <a-divider />

        <div class="content-sub-title content-sub-title-flex">
          <div class="title">区域性覆盖</div>
          <a-button type="link" @click="onAreaOpen">
            新增区域
          </a-button>
        </div>
        <a-table
          :columns="columns3"
          :data-source="addressArea"
          :pagination="false"
          :loading="false"
        >
          <template slot="area" slot-scope="record">
            {{ record.province }}{{ record.city }}{{ record.district }}
          </template>
          <template slot="status" slot-scope="record">
            <a-badge
              :status="statuText2[record.status].status"
              :text="statuText2[record.status].text"
            />
          </template>
          <template slot="action" slot-scope="record">
            <a-button
              type="link"
              @click="onChangeAreaStatus(record.areaId, record.status)"
            >
              <span v-if="record.status === 1">禁用</span>
              <span v-else>启用</span>
            </a-button>
            <a-button type="link" v-permission="'edit'">
              删除
            </a-button>
          </template>
        </a-table>
      </div>
    </div>

    <drawer :title="comboTitle" :visible="comboVisible">
      <a-form-model ref="ruleComboForm" :model="comboForm" :rules="comboRules">
        <div class="form-item">
          <a-form-model-item
            label="套餐名称"
            ref="servicename"
            prop="servicename"
          >
            <a-input
              v-model="comboForm.servicename"
              placeholder="请输入套餐名称"
            />
          </a-form-model-item>
          <a-form-model-item label="排序（开发配置）" ref="sort" prop="sort">
            <a-input
              v-model="comboForm.sort"
              placeholder="请输入排序（开发配置）"
            />
          </a-form-model-item>
        </div>

        <a-form-model-item
          label="上传套餐切图"
          extra="建议使用PNG格式图片；尺寸为1125px*537px"
          prop="picture"
        >
          <a-upload
            name="file"
            list-type="picture-card"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeImageUpload"
            :data="{
              token: qiniuData.token
            }"
            @change="onComboImgChange"
          >
            <div v-if="comboForm.picture">
              <img class="img" :src="comboForm.picture" />
            </div>
            <div v-else>
              <a-icon :type="imgLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传套餐切图
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-divider dashed />
        <div class="form-item">
          <a-form-model-item label="套餐原价（元）" ref="oprice" prop="oprice">
            <a-input v-model="comboForm.oprice" placeholder="请输入套餐原价" />
          </a-form-model-item>
          <a-form-model-item
            label="套餐实际售价"
            ref="promotionprice"
            prop="promotionprice"
          >
            <a-input
              v-model="comboForm.promotionprice"
              placeholder="请输入套餐实际售价"
            />
          </a-form-model-item>
        </div>
        <a-divider dashed />
        <div class="form-item-title-content">
          <span>套餐关联的服务项（用于部分活动页面展示用，不影响结算）</span>
          <a-button @click="onAddServingSelect">
            添加服务项
          </a-button>
        </div>
        <div v-for="(combo, index) in comboForm.combos" :key="combo.key">
          <div class="form-item">
            <a-form-model-item
              label="选择服务类型"
              :prop="'combos.' + index + '.servingId'"
              :rules="{
                required: true,
                message: '请先选择服务类型',
                trigger: 'change'
              }"
            >
              <a-select
                placeholder="请选择服务类型"
                v-model="combo.servingId"
                @change="onCategoryChange($event, index)"
              >
                <a-select-option v-for="subItem in servings" :key="subItem.id">
                  {{ subItem.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="选择服务项目"
              :prop="'combos.' + index + '.servingItemId'"
              :rules="{
                required: true,
                message: '请先选择服务项目',
                trigger: 'change'
              }"
            >
              <a-select
                placeholder="请选择服务项目"
                v-model="combo.servingItemId"
                @change="onServingChange($event, index)"
              >
                <a-select-option
                  v-for="subItem in combo.servingItems"
                  :key="subItem.id"
                >
                  {{ subItem.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <a-form-model-item
            label="选择家电设备"
            :prop="'combos.' + index + '.equipmentId'"
            :rules="{
              required: true,
              message: '请先选择家电设备',
              trigger: 'change'
            }"
          >
            <a-select placeholder="请选择家电设备" v-model="combo.equipmentId">
              <a-select-option
                v-for="subItem in combo.equipments"
                :key="subItem.id"
              >
                {{ subItem.name }}（{{ subItem.subtitle }}）
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>

        <div class="drawer-btn-wrap">
          <a-button @click="onComboClose">
            取消
          </a-button>
          <a-button type="primary" @click="onComboSubmit">
            提交
          </a-button>
        </div>
      </a-form-model>
    </drawer>

    <drawer :title="drawerTitle" :width="376" :visible="visible">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item
          class="ant-form-item-input"
          ref="name"
          label="分布点别名"
          prop="name"
        >
          <a-input
            v-model="form.name"
            placeholder="请输入分布点别名"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item label="选择省市区" prop="areaId">
          <a-cascader
            :options="cascaderAreaOptions"
            placeholder="请选择省市区"
            expand-trigger="hover"
            :field-names="fieldNames"
            v-model="form.areaId"
          />
        </a-form-model-item>
        <a-form-model-item
          ref="address"
          label="详细地址（街道、门牌号）"
          prop="address"
        >
          <a-select
            allow-clear
            show-search
            placeholder="请输入详细地址（街道、门牌号）"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            @search="handleAddressChange"
            @select="handleAddressSelect"
            :not-found-content="null"
            v-model="form.address"
          >
            <a-select-option
              v-for="(d, index) in addressReturn"
              :key="d.value + index"
            >
              {{ d.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="分布点经/纬度" prop="longiLatitue">
          <a-input
            v-model="form.longiLatitue"
            placeholder="请输入分布点经/纬度"
            disabled
          />
        </a-form-model-item>
        <a-form-model-item
          ref="distance"
          label="栅栏的方圆（公里数）"
          prop="distance"
        >
          <a-input
            v-model="form.distance"
            placeholder="请输入栅栏的方圆（公里数）"
            addon-after="km"
            @blur="
              () => {
                $refs.distance.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-btn-wrap">
        <a-button @click="handleDrawerClose">
          取消
        </a-button>
        <a-button type="primary" @click="handleDrawerSubmit">
          提交
        </a-button>
      </div>
    </drawer>

    <drawer :title="drawerTitle" :width="376" :visible="visible2">
      <a-form-model ref="ruleForm2" :model="form2" :rules="rules2">
        <a-form-model-item label="选择省市区" prop="areaId">
          <a-cascader
            :options="cascaderAreaOptions"
            placeholder="请选择省市区"
            expand-trigger="hover"
            :field-names="fieldNames"
            v-model="form2.areaId"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-btn-wrap">
        <a-button @click="handleDrawerClose2">
          取消
        </a-button>
        <a-button type="primary" @click="handleDrawerSubmit2">
          提交
        </a-button>
      </div>
    </drawer>

    <a-modal v-model="modalVisible" title="禁用 - 区域性" :closable="false">
      <div class="title">需要禁用的省市区</div>
      <a-cascader
        class="cascader"
        :options="cascaderAreaOptions"
        :field-names="fieldNames"
        v-model="areaIds"
        disabled
      />
      <a-alert type="warning" banner v-if="isAlert">
        <div class="a-alert" slot="message">
          <div class="left">
            系统检测到当前区域存在分布式（点对点）栅栏，是否一并禁用？
          </div>
          <a-button type="link">
            查看详情
          </a-button>
        </div>
      </a-alert>
      <template slot="footer">
        <a-button @click="handleModalCancel" :disabled="modalLoading">
          取消
        </a-button>
        <template v-if="isAlert">
          <a-button :loading="modalLoading" @click="handleModalConfirm(1)">
            仅禁用区域性
          </a-button>
          <a-button
            type="danger"
            :loading="modalLoading"
            @click="handleModalConfirm(2)"
          >
            全部禁用
          </a-button>
        </template>
        <a-button
          type="danger"
          :loading="modalLoading"
          @click="handleModalConfirm(1)"
          v-else
        >
          确认禁用
        </a-button>
      </template>
    </a-modal>

    <a-modal v-model="modalVisible2" title="修改预计时间（定时任务）">
      <a-form-model
        ref="ruleForm3"
        :model="form3"
        :rules="rules3"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="预计开启时间" prop="startAt">
          <a-date-picker v-model="form3.startAt" placeholder="开启时间" />
        </a-form-model-item>
        <a-form-model-item label="预计关闭时间" prop="endAt">
          <a-date-picker v-model="form3.endAt" placeholder="关闭时间" />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="handleModalCancel2">
          取消
        </a-button>
        <a-button type="primary" @click="handleModalConfirm2">
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Debounce } from '../../utils/public'
import moment from 'moment'
import drawer from '@/components/drawer'
let itemId = null
let areaStatus = null
let bcid = null
const comboForm = {
  servicename: '',
  oprice: '',
  promotionprice: '',
  picture: '',
  sort: '',
  combos: []
}
export default {
  data() {
    return {
      title: '',
      info: null,
      statuText: {
        0: '关闭',
        1: '开启'
      },
      linktypes: {
        1: '小程序',
        2: 'H5页面',
        3: '第三方小程序'
      },
      modoTypes: {
        1: '人工开启',
        2: '系统定时'
      },
      statuText2: [
        {
          status: 'default',
          text: '禁用'
        },
        {
          status: 'success',
          text: '启用'
        }
      ],
      columns: [
        {
          title: '排序',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '套餐名称',
          width: 200,
          dataIndex: 'servicename'
        },
        {
          title: '套餐主图',
          width: 100,
          scopedSlots: { customRender: 'picture' }
        },
        {
          title: '服务项目',
          width: 150,
          dataIndex: 'serName'
        },
        {
          title: '原价（元）',
          scopedSlots: { customRender: 'oprice' }
        },
        {
          title: '操作',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      columns2: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '分布点别名',
          width: 150,
          dataIndex: 'name'
        },
        {
          title: '省市区',
          width: 200,
          scopedSlots: { customRender: 'area' }
        },
        {
          title: '详细地址',
          width: 200,
          scopedSlots: { customRender: 'address' }
        },
        {
          title: '分布点经/纬度',
          width: 200,
          dataIndex: 'longiLatitue'
        },
        {
          title: '方圆（公里数）',
          width: 150,
          scopedSlots: { customRender: 'distance' }
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 250,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columns3: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '省市区',
          scopedSlots: { customRender: 'area' },
          width: 200
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 200,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      addressPoints: [],
      addressArea: [],
      comboTitle: '新增活动商品',
      comboVisible: false, // 套餐弹窗显示状态
      comboForm: JSON.parse(JSON.stringify(comboForm)),
      servings: [], // 服务类型列表
      comboRules: {
        servicename: [
          { required: true, message: '请输入套餐名称', trigger: 'blur' }
        ],
        sort: [{ required: true, message: '请输入套餐排序', trigger: 'blur' }],
        picture: [
          { required: true, message: '请上传套餐切图', trigger: 'change' }
        ],
        oprice: [
          { required: true, message: '请输入套餐原价', trigger: 'blur' }
        ],
        promotionprice: [
          { required: true, message: '请输入套餐实际售价', trigger: 'blur' }
        ]
      },
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      imgLoading: false,
      drawerTitle: '',
      visible: false,
      visible2: false,
      form: {
        name: '',
        areaId: undefined,
        address: undefined,
        longiLatitue: '',
        distance: ''
      },
      form2: {
        areaId: undefined
      },
      form3: {
        startAt: undefined,
        endAt: undefined
      },
      rules: {
        // 绑定地区
        name: [
          { required: true, message: '请输入分布点别名', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        distance: [
          { required: true, message: '请输入栅栏的方圆', trigger: 'blur' }
        ],
        areaId: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },
      rules2: {
        areaId: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },
      rules3: {
        startAt: [
          { required: true, message: '请选择开启时间', trigger: 'change' }
        ],
        endAt: [
          { required: true, message: '请选择关闭时间', trigger: 'change' }
        ]
      },
      cascaderAreaOptions: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      addressReturn: [],
      modalVisible: false,
      isAlert: false,
      modalLoading: false,
      areaIds: undefined,
      modalVisible2: false,
      modalLoading2: false
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  components: {
    drawer
  },
  created() {
    this.title = this.$getPageTitle()
    bcid = this.$route.query.id
    this.getActivityInfo()
    this.getActivityComboAddress()
    this.getActivityComboArea()
  },
  methods: {
    getActivityInfo() {
      const data = {
        bcid
      }
      this.$axios.getActivityInfo(data).then((res) => {
        this.info = res.data.data
      })
    },
    onComboOpen() {
      this.comboTitle = '新增活动商品'
      this.comboForm.combos.push({
        key: 0,
        servingId: undefined,
        servingItemId: undefined,
        equipmentId: undefined,
        servingItems: [], // 服务列表
        equipments: [] // 设备列表
      })
      this.comboVisible = true
      this.getPunlicService()
    },
    onComboEdit(e) {
      // 编辑商品
      this.onComboOpen()
      this.comboTitle = '编辑活动商品'
      const combos = this.comboForm.combos
      e.categoryId.forEach((event, index) => {
        // 获取对应服务类型的服务项目
        if (index) {
          combos.push({
            servingId: Number(event),
            servingItemId: undefined,
            equipmentId: undefined,
            servingItems: [],
            equipments: []
          })
        } else {
          combos[0].servingId = Number(event)
        }

        if (!combos[index].servingItems.length) {
          this.getPublicServiceItems(event, index)
        }
      })
      e.servingId.forEach((event, index) => {
        // 获取对应服务项目的设备
        combos[index].servingItemId = Number(event)

        if (!combos[index].equipments.length) {
          this.getPublicServiceItemsEquipment(event, index)
        }
      })
      e.servinginfoId.forEach((event, index) => {
        combos[index].equipmentId = Number(event)
      })
      this.comboForm = {
        id: e.id,
        bcid: bcid,
        servicename: e.servicename,
        oprice: e.oprice,
        promotionprice: e.promotionprice,
        picture: e.picture,
        sort: e.sort,
        combos: combos
      }
      this.comboVisible = true
    },
    getPunlicService() {
      // 获取服务类型列表
      const data = {
        page: 1,
        limit: 100
      }
      this.$axios.getPunlicService(data).then((res) => {
        this.servings = res.data.data.list
      })
    },
    getPublicServiceItems(e, i) {
      // 获取服务列表
      const data = {
        categoryId: e
      }
      this.$axios.getPublicServiceItems(data).then((res) => {
        const val = res.data.data
        this.comboForm.combos[i].servingItems = val
      })
    },
    onCategoryChange(e, i) {
      // 选择服务类型
      this.getPublicServiceItems(e, i)
    },
    onServingChange(e, i) {
      // 选择服务项
      this.getPublicServiceItemsEquipment(e, i)
    },
    getPublicServiceItemsEquipment(e, i) {
      // 获取服务设备列表
      const data = {
        servingId: e
      }
      this.$axios.getPublicServiceItemsEquipment(data).then((res) => {
        // this.devices.splice(i, 1, res.data.data)
        this.comboForm.combos[i].equipments = res.data.data
      })
    },
    onComboImgChange(info) {
      // 上传套餐切图
      if (info.file.status === 'uploading') {
        if (this.comboForm.picture) {
          this.comboForm.picture = ''
        }
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.comboForm.picture = this.qiniuData.host + info.file.response.key
        this.imgLoading = false
      }
    },
    beforeImageUpload() {
      return true
    },
    // async beforeImageUpload (file) { // 图片上传检验
    //   const examine = {
    //     width: 1125,
    //     height: 537
    //   }
    //   await this.$examineImgWH(file, examine)
    // },
    onAddServingSelect() {
      // 添加服务项选择框
      this.comboForm.combos.push({
        key: Date.now(),
        servingItems: [],
        equipments: []
      })
    },
    getActivityComboAddress() {
      const data = {
        bcid
      }
      this.$axios.getActivityComboAddress(data).then((res) => {
        this.addressPoints = res.data.data.data
      })
    },
    getActivityComboArea() {
      const data = {
        bcid
      }
      this.$axios.getActivityComboArea(data).then((res) => {
        this.addressArea = res.data.data.data
      })
    },
    onComboClose() {
      // 关闭套餐编辑抽屉
      this.comboVisible = false
      this.$refs.ruleComboForm.resetFields()
      this.comboForm = JSON.parse(JSON.stringify(comboForm))
    },
    onComboSubmit() {
      // 新增套餐
      this.$refs.ruleComboForm.validate((valid) => {
        if (valid) {
          const categoryId = []
          const servingId = []
          const servinginfoId = []
          this.comboForm.combos.forEach((e) => {
            categoryId.push(e.servingId)
            servingId.push(e.servingItemId)
            servinginfoId.push(e.equipmentId)
          })
          this.comboForm.bcid = bcid
          this.comboForm.categoryId = categoryId
          this.comboForm.servingId = servingId
          this.comboForm.servinginfoId = servinginfoId
          delete this.comboForm.combos
          if (this.comboForm.id) {
            this.$axios.changeActivityCombo(this.comboForm).then(() => {
              this.onComboClose()
              this.$message.success('操作成功')
              this.getActivityInfo()
            })
          } else {
            this.$axios.addActivityCombo(this.comboForm).then(() => {
              this.onComboClose()
              this.$message.success('操作成功')
              this.getActivityInfo()
            })
          }
        }
      })
    },
    onComboDel(id) {
      // 删除商品
      this.$confirm({
        title: '确定删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            bcid,
            id
          }
          this.$axios.removeActivityCombo(data).then(() => {
            this.$message.success('操作成功')
            this.getActivityInfo()
          })
        }
      })
    },
    onAddressOpen() {
      this.drawerTitle = '新增分布点'
      this.visible = true
      this.getArea(1)
    },
    onAddressEdit(e) {
      // 编辑地址栅栏
      this.drawerTitle = '编辑分布点'
      this.form.name = e.name
      this.form.address = e.address
      this.form.longiLatitue = e.longiLatitue
      this.form.distance = e.distance
      this.address = e.address
      itemId = e.id
      this.visible = true
      this.getArea(1, e.areaId)
    },
    async getArea(type, id) {
      const area = await this.$axios.getArea()
      this.cascaderAreaOptions = area.nested
      if (type === 1 && id) {
        // 分布式
        this.form.areaId = this.findFatherId(area.tiled, id)
      }
      if (type === 2 && id) {
        // 区域
        this.areaIds = this.findFatherId(area.tiled, id)
        this.checkActivityComboArea()
      }
    },
    findFatherId(arr, id) {
      // 查找区域id的父级id
      const idArr = [id]
      const forFn = function(val) {
        arr.forEach((item) => {
          if (item.id === val) {
            idArr.push(item.id)
            forFn(item.parent_id)
          }
        })
      }
      forFn(id)
      idArr.reverse() // 颠倒排序
      return idArr
    },
    handleAddressChange: Debounce(function(e) {
      // 地址搜索
      if (!e) return
      const baseUrl = `https://apis.map.qq.com/ws/place/v1/search?keyword=${e}&key=${process.env.VUE_APP_QQMAPKEY}&output=jsonp&boundary=region(中国)`
      this.$jsonp(baseUrl)
        .then((res) => {
          // console.log(res)
          const data = res.data.map((v) => {
            if (v.address && v.title) {
              return {
                value: `${v.address}${v.title}`,
                longiLatitue: `${v.location.lng},${v.location.lat}`
              }
            }
          })
          this.addressReturn = data
        })
        .catch((err) => {
          console.log(err)
        })
    }, 500),
    handleAddressSelect(key) {
      // 监听地址选择
      const index = key.substring(key.length - 1)
      const item = this.addressReturn[index]
      this.form.longiLatitue = item.longiLatitue
    },
    handleDrawerClose() {
      // 关闭地址栅栏表单抽屉
      this.visible = false
      itemId = ''
      this.$refs.ruleForm.resetFields()
    },
    handleDrawerSubmit() {
      // 提交地址栅栏表单数据
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          const areaId = data.areaId
          data.bcid = bcid
          data.areaId = areaId[areaId.length - 1]
          const address = this.form.address.substring(
            0,
            this.form.address.length - 1
          )
          data.address = address
          if (itemId) {
            data.id = itemId
            this.$axios.changeActivityComboAddress(data).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getActivityComboAddress()
            })
          } else {
            this.$axios.addActivityComboAddress(data).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getActivityComboAddress()
            })
          }
        }
      })
    },
    onChangeAddressStatus(id, status) {
      // 设置地址栅栏状态
      let title = ''
      if (status === 1) {
        title = '确定禁用？'
        status = 0
      } else {
        title = '确定启用？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            bcid,
            id,
            status
          }
          this.$axios.changeActivityComboAddressStatus(data).then(() => {
            this.$message.success('操作成功')
            this.getActivityComboAddress()
          })
        }
      })
    },
    removeActivityComboAddress(id) {
      // 删除地址栅栏
      this.$confirm({
        title: '确定删除？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id
          }
          this.$axios.removeActivityComboAddress(data).then(() => {
            this.$message.success('操作成功')
          })
        }
      })
    },
    onAreaOpen() {
      // 新增区域性覆盖
      this.drawerTitle = '新增区域性覆盖'
      this.visible2 = true
      this.getArea()
    },
    handleDrawerClose2() {
      // 关闭表单抽屉
      this.visible2 = false
      this.$refs.ruleForm2.resetFields()
    },
    handleDrawerSubmit2() {
      // 提交表单数据
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form2))
          data.bcid = bcid
          const areaId = data.areaId
          data.areaId = areaId[areaId.length - 1]
          console.log(data)
          this.$axios.addActivityComboArea(data).then(() => {
            this.$message.success('操作成功')
            this.handleDrawerClose2()
            this.getActivityComboArea()
          })
        }
      })
    },
    handleModalCancel() {
      // 取消禁用区域
      this.modalVisible = false
      this.modalLoading = false
    },
    onChangeAreaStatus(id, status) {
      // 禁用区域
      areaStatus = status
      this.modalVisible = true
      this.getArea(2, id)
    },
    checkActivityComboArea() {
      // 检测服务区域覆盖是否有分布点
      const data = {
        bcid: bcid,
        areaId: this.areaIds[this.areaIds.length - 1]
      }
      this.$axios.checkActivityComboArea(data).then((res) => {
        const lists = res.data.data
        this.isAlert = lists.length > 0 ? true : false
      })
    },
    handleModalConfirm(type) {
      // 区域禁用
      this.modalLoading = true
      const data = {
        bcid: bcid,
        areaId: this.areaIds[this.areaIds.length - 1],
        setType: type,
        status: areaStatus === 1 ? 0 : 1 // 1: 正常; 0: 禁用
      }
      this.$axios.changeActivityComboAreaStatus(data).then(() => {
        this.$message.success('操作成功')
        this.handleModalCancel()
        this.getActivityComboArea()
      })
    },
    handleModalCancel2() {
      // 取消修改活动时间
      this.$refs.ruleForm3.resetFields()
      this.modalVisible2 = false
      this.modalLoading2 = false
    },
    handleModalConfirm2() {
      // 确定修改活动时间
      this.modalLoading2 = true
      const data = {
        bcid: bcid,
        startAt: moment(this.form3.startAt).format('YYYY-MM-DD') + ' 00:00:00',
        endAt: moment(this.form3.endAt).format('YYYY-MM-DD') + ' 23:59:59'
      }
      this.$axios.changeActivityTime(data).then(() => {
        this.$message.success('操作成功')
        this.handleModalCancel2()
        this.getActivityInfo()
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.main-content:not(:last-child) {
  padding: 24px 24px 0 24px;
}

.content-item-header {
  margin-bottom: 24px;
}

.content-item-header-left {
  justify-content: flex-start;
}

.content-sub-title-flex {
  display: flex;
  align-items: center;
}

.form-item-title-content {
  margin-bottom: 16px;
}

.cover {
  width: 40px;
}

.ant-calendar-picker {
  width: 100%;
}
</style>
